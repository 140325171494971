import React, { useState } from "react"
import * as styles from "./index.module.scss"
import {
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from "reactstrap"

import Image1 from "./relaunch/front.jpg"
import Image2 from "./relaunch/team.jpg"
import Image3 from "./relaunch/inside.jpg"

const isMobile = typeof window !== "undefined" && window.innerWidth < 576

const items = [
  {
    src: isMobile ? Image1 : Image1,
    captionTitle: "",
    caption: "",
  },
  {
    src: isMobile ? Image2 : Image2,
    captionTitle: "",
    caption: "",
  },
  {
    src: isMobile ? Image3 : Image3,
    captionTitle: "",
    caption: "",
  },
]

const SlideShow = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map(item => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.captionTitle} />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.captionTitle}
        />
      </CarouselItem>
    )
  })

  return (
    <div className={styles.slider_wrapper}>
      <div className="container">
        <div className={styles.carousel_wrapper}>
          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {slides}
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default SlideShow
