const divisaruWalapane = require("./data/divisaruWalapane")
const beeGreen = require("./data/beeGreen")
const greenHouseOrganics = require("./data/greenHouseOrganics")
const mihimandalaOrganicProducts = require("./data/mihimandalaOrganicProducts")
const forestGardenOrganics = require("./data/forestGardenOrganics")
const backOfBeyond = require("./data/backOfBeyond")
const greenCareOrganic = require("./data/greenCareOrganic")
const viaVillage = require("./data/viaVillage")

const farms = [
  divisaruWalapane,
  beeGreen,
  greenHouseOrganics,
  mihimandalaOrganicProducts,
  forestGardenOrganics,
  backOfBeyond,
  greenCareOrganic,
  viaVillage,
]

module.exports = farms
