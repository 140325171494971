const divisaruWalapane = {
  name: "Divisaru Organic Farmers Association",
  location: "Walapane",
  images: 20,
  slug: "walapane",
  certifications: "PGS Certified",
  description: `This is a farmer group consist around 50 small holder organic farmers produce organic vegetables in Nuwara Eliya District.
Owita Organics provides consultancy services on crop planning, consistent production, and organic farming practices for the members of the farmer group. Majority of the production sold through OWITA Organics market channels.`,
}

module.exports = divisaruWalapane
