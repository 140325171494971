const greenHouseOrganics = {
  name: "Green House Organic Garden",
  location: "Nuwara Eliya",
  images: 5,
  slug: "nuwara-eliya",
  certifications: "Control Union Certified",
  description: `This is an organic farm that produces cool climate vegetables in Sri Lanka's hill country. They are focused on protecting the environment and natural resources, efficient logistics, and providing a consistent affordable supply of organic produce to local consumers.
This has an organic certificate from the Sri Lanka Standards Institute and is verified under a local participatory guarantee system.`,
}
module.exports = greenHouseOrganics
