const beeGreen = {
  name: "Bee Green Organic Farm",
  location: "Chilaw",
  images: 14,
  slug: "chilaw",
  certifications: "PGS Certified",
  description: `This was started as a pedagogical farm to provide education on local organic agriculture. Their mission is offer organic products for better health, protect the environment, and provide nature experience for kids.
This farm cultivates fruits, vegetables, leafy greens, and medicinal plants and is developing a forest area. They have 11.5 acres verified under the local organic participatory guarantee system (PGS).`,
}

module.exports = beeGreen
