import React from "react"
import * as s from "./index.module.scss"
import { Link } from "gatsby"
// import { HiOutlineLocationMarker } from "react-icons/hi"
import { AiOutlineSafetyCertificate } from "react-icons/ai"
import { BiChevronRight } from "react-icons/bi"

const FarmBlock = ({ farm, noBorder = false }) => {
  const { name, slug, location, certifications } = farm
  return (
    <div className="col-6 col-md-6 col-xl-4">
      <div className={`${s.farm_block} ${noBorder ? s.noBorder : ""}`}>
        <div className={s.image}>
          <img src={`/farms/${slug}/cover.jpg`} alt={name} />
        </div>
        <div className={s.description}>
          <div>
            <h4>Farm at {location}</h4>
            <ul>
              {/*<li>*/}
              {/*  <HiOutlineLocationMarker />*/}
              {/*  {location}*/}
              {/*</li>*/}
              <li>
                <AiOutlineSafetyCertificate />
                {certifications}
              </li>
            </ul>
            <Link
              className="btn btn-link px-0 btn-sm d-flex align-items-center justify-content-center justify-content-md-start"
              to={`/our-partner-farms/${slug}`}
            >
              Read more
              <BiChevronRight />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FarmBlock
