const backOfBeyond = {
  name: "Back of Beyond Agro PVT LTD",
  location: "Sigiriya",
  images: 13,
  slug: "sigiriya",
  certifications: "PGS Certified",
  description: `This is one of the leading Eco Hotel chain manage their properties in Sigiriya, Pidurangala, Tangalle, Yala, Anuradhapura, Negombo and Kandy. This is the agriculture initiative which manage their agricultural projects in Sigiriya, Pidurangala and Tangalle`,
}

module.exports = backOfBeyond
