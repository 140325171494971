import React, { useContext, useEffect, useState } from "react"
import { StoreContext } from "../../../contexts/Store"
import Product from "../../OrganicStore/Product"
import { Link } from "gatsby"

const visibleCategories = [
  "organic-vegetables",
  "organic-fruits",
  "natural-traditional-rice",
  "other-healthy-food",
]

const HomeProductsBlock = () => {
  const [vegetables, setVegetables] = useState([{}, {}, {}, {}, {}, {}])
  const [fruits, setFruits] = useState([{}, {}, {}, {}, {}, {}])
  const [rice, setRice] = useState([{}, {}, {}, {}, {}, {}])
  const [other, setOther] = useState([{}, {}, {}, {}, {}, {}])
  const {
    store: { products },
  } = useContext(StoreContext)

  useEffect(() => {
    if (!products || products.length === 0) return
    setVegetables(
      products
        .filter(p => p.category.slug === visibleCategories[0])
        .slice(0, 6),
    )
    setFruits(
      products
        .filter(p => p.category.slug === visibleCategories[1])
        .slice(0, 6),
    )
    setRice(
      products
        .filter(p => p.category.slug === visibleCategories[2])
        .slice(0, 6),
    )
    setOther(
      products
        .filter(p => p.category.slug === visibleCategories[3])
        .slice(0, 6),
    )
  }, [products])

  return (
    <div className={"container mt-5 mb-1 py-5 mb-lg-4 mb-xl-5"}>
      <h2 className={"text-center"}>Online Shop</h2>
      <h3>Organic Vegetables</h3>
      <div className="row">
        {vegetables.map((product, idx) => {
          return <Product product={product} key={idx} homePage />
        })}
      </div>
      <div className="text-center pb-5">
        <Link
          to={`/organic-store/${visibleCategories[0]}`}
          className={"btn btn-primary mt-3 mb-5"}
        >
          Shop Organic Vegetables
        </Link>
      </div>

      <h3 className={""}>Organic Fruits</h3>
      <div className="row">
        {fruits.map((product, idx) => {
          return <Product product={product} key={idx} homePage />
        })}
      </div>
      <div className="text-center pb-5">
        <Link
          to={`/organic-store/${visibleCategories[1]}`}
          className={"btn btn-primary mt-3 mb-5"}
        >
          Shop Organic Fruits
        </Link>
      </div>

      <h3>Natural &amp; Traditional Rice</h3>
      <div className="row">
        {rice.map((product, idx) => {
          return <Product product={product} key={idx} homePage />
        })}
      </div>
      <div className="text-center pb-5">
        <Link
          to={`/organic-store/${visibleCategories[2]}`}
          className={"btn btn-primary mt-3 mb-5"}
        >
          Shop Rice Varieties
        </Link>
      </div>

      <h3>Other Healthy Food</h3>
      <div className="row">
        {other.map((product, idx) => {
          return <Product product={product} key={idx} homePage />
        })}
      </div>
      <div className="text-center pb-2 pb-lg-0">
        <Link
          to={`/organic-store/${visibleCategories[3]}`}
          className={"btn btn-primary mt-3"}
        >
          Shop Other Healthy Food
        </Link>
      </div>
    </div>
  )
}

export default HomeProductsBlock
